import React from 'react';
import { Card, CardFooter, Image, Button } from "@nextui-org/react";
import BgProject1 from '../images/card1.jpg'
import BgProject2 from '../images/card2.jpg'
import BgProject3 from '../images/card3.jpg'
import BgProject4 from '../images/card4.jpg'
import Footer from './Footer';

import './styles/project-section.css';


function ProjectSection(props) {
    const { title, description, image } = props;
    return (
        <section className='landing-section h-screen w-screen text-center relative overflow-hidden content-center items-center ' data-header-color='black'>

            <div className='flex flex-row flex-wrap gap-4 h-full w-full justify-center  items-center overflow-hidden pt-60 content-projects'>
                <div className='max-w-md'>
                    <Card>
                        <Image
                            alt="Alcaldia de Villagarzon"
                            className="w-full object-cover h-[100px]"
                            src={BgProject1}
                            width="100%"
                        />
                        <CardFooter className="flex flex-col justify-between before:bg-white/10 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 shadow-small ml-1 z-10">
                            <h1 className="text-lg">Alcaldia de Villagarzón Putumayo</h1>
                            <p className="text-tiny text-white/80 responsive-element">Edificio administrativo de la alcaldía de Villagarzón</p>
                        </CardFooter>
                    </Card>
                </div>
                <div className='max-w-md'>
                    <Card>
                        <Image
                            alt="Alcaldia de Villagarzon"
                            className="w-full object-cover h-[100px]"
                            src={BgProject2}
                            width="100%"
                        />
                        <CardFooter className="flex flex-col justify-between before:bg-white/10 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 shadow-small ml-1 z-10">
                            <h1 className="text-lg">Plaza de mercado de Villagarzón</h1>
                            <p className="text-tiny text-white/80 responsive-element">Edificación de uso agroindustrial y comercial  </p>
                        </CardFooter>
                    </Card>
                </div>
                <div className='max-w-md responsive-element'>
                    <Card>
                        <Image
                            alt="Alcaldia de Villagarzon"
                            className="w-full object-cover h-[100px]"
                            src={BgProject3}
                            width="100%"
                        />
                        <CardFooter className="flex flex-col justify-between before:bg-white/10 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 shadow-small ml-1 z-10">
                            <h1 className="text-lg">Coliseo Guillermo Valencia</h1>
                            <p className="text-tiny text-white/80">Coliseo cubierto en instalaciones educativas</p>
                        </CardFooter>
                    </Card>
                </div>
                <div className='max-w-md responsive-element'>
                    <Card>
                        <Image
                            alt="Alcaldia de Villagarzon"
                            className="w-full object-cover h-[100px]"
                            src={BgProject4}
                            width="100%"
                        />
                        <CardFooter className="flex flex-col justify-between before:bg-white/10 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 shadow-small ml-1 z-10">
                            <h1 className="text-lg">Centro de adulto mayor</h1>
                            <p className="text-tiny text-white/80">
                                Instalaciones para el bienestar de los adultos mayores
                            </p>
                        </CardFooter>
                    </Card>
                </div>
                <Footer />


            </div>
        </section >
    );
}

export default ProjectSection;


