import React from 'react';
import ArmelcoLogoName from '../images/ArmelcoLogoName';
import FlechaAbajoIcon from '../images/FlechaAbajoIcon';
import BackgroundImage from '../images/background.jpg';
import './styles/home-section.css';

function HomeSection() {
    return (
        <section className='landing-section h-screen w-screen bg-slate-500 text-center relative overflow-hidden' data-header-color='black'>

            <div className='z-30 relative h-full flex flex-col'>
                <header className='logo-center'>
                    <ArmelcoLogoName />
                </header>
                <footer className='flex flex-col flex-grow  justify-end '>
                    <div className='flex justify-center'>
                        <FlechaAbajoIcon />
                    </div>
                </footer>
            </div>
            <div className='absolute top-0 bottom-0 z-10'>
                <img src={BackgroundImage} className='object-cover w-screen h-full'></img>
            </div>
        </section>
    );
}

export default HomeSection;
