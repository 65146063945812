import React, { useState, useEffect } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, RadioGroup, Radio } from "@nextui-org/react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import PdfIcon from "../images/pdfIcon";
import NoPdfIcon from "../images/noPdfIcon";
import { formatNumber } from './utils'

import { detailContract } from "../services/contratos";
import { getValueSM } from "../services/valorsm";

export default function ContractDetail(props) {
    const url_contract = props.url_contract;

    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const [yearContratSM, setYearContractSM] = useState(
        {
            contractYear: '',
            contractSM: '',
        }
    );

    const [currentSM, setCurrentSM] = useState(
        {
            currentYear: '',
            currentSM: '',
        }
    );

    const [flat, setFlat] = useState(false);
    const [currentContractSM, setCurrentContractSM] = useState()

    const [contract, setContract] = useState({
        numero: '',
        year: '',
        numero_en_proponente: '',
        objeto: '',
        tipo_de_obra: '',
        entidad_contratante: '',
        valor_ejecutado: '',
        plazo_en_dias: '',
        fecha_terminacion: '',
        porcentaje_participacion: '',
        valor_en_sm: '',
        departamento: '',
        municipio: '',
        contrato: '',
        adicional1: '',
        adicional2: '',
        acta_recibo_final: '',
        acta_liquidacion: '',
        certificacion_de_obra: '',
        certificacion_director: '',
        acuerdo_consorcial: '',
        empresa: '',

    });

    const updateSMValue = (contractYear, contractValue) => {
        Promise.all([
            getValueSM(contractYear),
            getValueSM(new Date().getFullYear())
        ])
            .then(([contractYearData, currentYearData]) => {
                const contractYearSM = contractYearData.results[0]?.valor;
                const currentYearSM = currentYearData.results[0]?.valor;

                setYearContractSM({
                    contractYear: contractYearData.results[0]?.year,
                    contractSM: contractYearSM,
                });

                setCurrentSM({
                    currentYear: currentYearData.results[0]?.year,
                    currentSM: currentYearSM,
                });

                if (contractYearSM && currentYearSM) {
                    setCurrentContractSM(formatNumber((contractValue / contractYearSM) * currentYearSM));
                } else {
                    setCurrentContractSM('');
                }

                if (contractYearSM) {
                    const numberOfMinimumWages = (contractValue / contractYearSM).toFixed(2);
                    setContract(prevState => ({
                        ...prevState,
                        numberOfMinimumWages: numberOfMinimumWages
                    }));
                } else {
                    setContract(prevState => ({
                        ...prevState,
                        numberOfMinimumWages: ''
                    }));
                }
            });
    }



    useEffect(() => {
        detailContract(url_contract)
            .then(data => {
                const endDate = new Date(data.fecha_terminacion);
                const endYear = endDate.getFullYear();
                updateSMValue(endYear, data.valor_ejecutado);
                setContract({
                    numero: data.numero,
                    year: data.year,
                    numero_en_proponente: data.numero_en_proponente,
                    objeto: data.objeto,
                    tipo_de_obra: data.tipo_de_obra,
                    entidad_contratante: data.entidad_contratante,
                    valor_ejecutado: data.valor_ejecutado,
                    plazo_en_dias: data.plazo_en_dias,
                    fecha_terminacion: data.fecha_terminacion,
                    porcentaje_participacion: data.porcentaje_participacion,
                    valor_en_sm: data.valor_en_sm,
                    departamento: data.departamento,
                    municipio: data.municipio,
                    contrato: data.contrato,
                    adicional1: data.adicional1,
                    adicional2: data.adicional2,
                    acta_recibo_final: data.acta_recibo_final,
                    acta_liquidacion: data.acta_liquidacion,
                    certificacion_de_obra: data.certificacion_de_obra,
                    certificacion_director: data.certificacion_director,
                    acuerdo_consorcial: data.acuerdo_consorcial,
                    empresa: data.empresa,
                });
            });
    }, [isOpen]);


    return (
        <div className="dark">
            <Button color='warning' variant='solid' onPress={onOpen}>Detalles</Button>
            <Modal
                backdrop="blur"
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                size="5xl"
                scrollBehavior={'outside'}
                classNames={{
                    body: "py-6",
                    backdrop: "dark",
                    base: "border-[#292f46]",
                    header: "border-b-[1px] border-[#292f46]",
                    footer: "border-t-[1px] border-[#292f46",
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <div>
                            <ModalHeader className="flex flex-col gap-1">{contract.objeto}</ModalHeader>
                            <ModalBody>
                                <Table aria-label="Detalles del contrato">
                                    <TableHeader >
                                        <TableColumn key='descripción'>DESCRIPCIÓN</TableColumn>
                                        <TableColumn key='valor'>VALOR</TableColumn>
                                    </TableHeader>
                                    <TableBody>
                                        <TableRow key='empresa'>
                                            <TableCell>Empresa</TableCell>
                                            <TableCell>{contract.empresa}</TableCell>
                                        </TableRow>
                                        <TableRow key='numero'>
                                            <TableCell>Numero</TableCell>
                                            <TableCell>{contract.numero}</TableCell>
                                        </TableRow>
                                        <TableRow key='numero_en_proponente'>
                                            <TableCell>Numero en proponente</TableCell>
                                            <TableCell>{contract.numero_en_proponente}</TableCell>
                                        </TableRow>
                                        <TableRow key='tipo_de_obra'>
                                            <TableCell>Categoría</TableCell>
                                            <TableCell>{contract.tipo_de_obra}</TableCell>
                                        </TableRow>
                                        <TableRow key='entidad_contratante'>
                                            <TableCell>Entidad contratante</TableCell>
                                            <TableCell>{contract.entidad_contratante}</TableCell>
                                        </TableRow>
                                        <TableRow key='valor_ejecutado'>
                                            <TableCell>Valor ejecutado</TableCell>
                                            <TableCell>{formatNumber(contract.valor_ejecutado)}</TableCell>
                                        </TableRow>
                                        <TableRow key='numero_salarios_minimos'>
                                            <TableCell>Número de Salarios Mínimos</TableCell>
                                            <TableCell>{contract.numberOfMinimumWages}</TableCell>
                                        </TableRow>

                                        <TableRow key='valor_en_sm'>
                                            <TableCell>Valor en salarios minimos actualizado</TableCell>
                                            <TableCell>{currentContractSM}</TableCell>
                                        </TableRow>
                                        <TableRow key='porcentaje_participacion'>
                                            <TableCell>Porcentaje de participación</TableCell>
                                            <TableCell>{parseInt(contract.porcentaje_participacion * 100)}%</TableCell>
                                        </TableRow>
                                        <TableRow key='fecha_terminacion'>
                                            <TableCell>Fecha de terminación</TableCell>
                                            <TableCell>{contract.fecha_terminacion}</TableCell>
                                        </TableRow>
                                        <TableRow key='departamento'>
                                            <TableCell>Departamento</TableCell>
                                            <TableCell>{contract.departamento}</TableCell>
                                        </TableRow>
                                        <TableRow key='municipio'>
                                            <TableCell>Municipio</TableCell>
                                            <TableCell>{contract.municipio}</TableCell>
                                        </TableRow>
                                        <TableRow key='contrato'>
                                            <TableCell>Contrato</TableCell>
                                            <TableCell> {contract.contrato ? <a target="_blank" rel="noopener noreferrer" href={contract.contrato}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                        </TableRow>
                                        <TableRow key='adicional1'>
                                            <TableCell>Adicional 1</TableCell>
                                            <TableCell>{contract.adicional1 ? <a target="_blank" rel="noopener noreferrer" href={contract.adicional1}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                        </TableRow>
                                        <TableRow key='adicional2'>
                                            <TableCell>Adicional 2</TableCell>
                                            <TableCell>{contract.adicional2 ? <a target="_blank" rel="noopener noreferrer" href={contract.adicional2}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                        </TableRow>
                                        <TableRow key='acta_recibo_final'>
                                            <TableCell>Acta de recibo final</TableCell>
                                            <TableCell>{contract.acta_recibo_final ? <a target="_blank" rel="noopener noreferrer" href={contract.acta_recibo_final}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                        </TableRow>
                                        <TableRow key='acta_liquidacion'>
                                            <TableCell>Acta de liquidación</TableCell>
                                            <TableCell>{contract.acta_liquidacion ? <a target="_blank" rel="noopener noreferrer" href={contract.acta_liquidacion}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                        </TableRow>
                                        <TableRow key='certificacion_de_obra'>
                                            <TableCell>Certificación de obra</TableCell>
                                            <TableCell>{contract.certificacion_de_obra ? <a target="_blank" rel="noopener noreferrer" href={contract.certificacion_de_obra}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                        </TableRow>
                                        <TableRow key='certificacion_director'>
                                            <TableCell>Certificación director de obra</TableCell>
                                            <TableCell>{contract.certificacion_director ? <a target="_blank" rel="noopener noreferrer" href={contract.certificacion_director}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                        </TableRow>
                                        <TableRow key='acuerdo_consorcial'>
                                            <TableCell>Acuerdo consorcial</TableCell>
                                            <TableCell>{contract.acuerdo_consorcial ? <a target="_blank" rel="noopener noreferrer" href={contract.acuerdo_consorcial}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="warning" variant="solid" onPress={onClose}>
                                    Cerrar
                                </Button>
                            </ModalFooter>
                        </div>
                    )}
                </ModalContent>
            </Modal>
        </div >
    );
}