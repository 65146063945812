const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const getCompanies = async () => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `JWT ${localStorage.getItem('access')}`);
    myHeaders.append('Accept', 'application/json');
    const url = `/api/companies/`;
    const query = `${REACT_APP_API_URL}${url}`;
    const response = await fetch(query, {
        method: 'GET',
        headers: myHeaders,
    });
    const companies = response.json();
    return companies
}

export const getDetailCompany = async (urlCompany) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `JWT ${localStorage.getItem('access')}`);
    myHeaders.append('Accept', 'application/json');
    const url = `${REACT_APP_API_URL}${urlCompany}/`;
    const response = await fetch(url, {
        method: 'GET',
        headers: myHeaders,
    });
    const company = response.json();
    return company
}
