import React, { useState, useEffect } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { Tabs, Tab } from "@nextui-org/react";

import PdfIcon from "../images/pdfIcon";
import NoPdfIcon from "../images/noPdfIcon";
import { formatNumber } from './utils'

import { getDetailCompany } from "../services/companies";

export default function CompanyDetail(props) {
    const url = props.url;

    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const [company, setCompany] = useState({
        name: '',
        nit: '',
        logo: '',
        certificate_existence: '',
        date_certificate_existence: '',
        certificate_proponent: '',
        date_certificate_proponent: '',
        operating_income: '',
        date_operating_income: '',
        fin_docs: '',
        date_fin_docs: '',
        tax_return: '',
        date_tax_return: '',
        shareholder_structure: '',
        date_shareholder_structure: '',
        disability_certificate: '',
        date_disability_certificate: '',
        acct_docs1: '',
        date_acct_docs1: '',
        acct_docs2: '',
        date_acct_docs2: '',
        fiscal_review_documents: '',
        date_fiscal_review_documents: '',
        social_security_certificate: '',
        date_social_security_certificate: '',
        rut: '',
        date_rut: '',
        k_residual: '',
        date_k_residual: '',
        aditional_legal_documents1: '',
        name_aditional_legal_documents1: '',
        date_aditional_legal_documents1: '',
        aditional_legal_documents2: '',
        name_aditional_legal_documents2: '',
        date_aditional_legal_documents2: '',
        aditional_legal_documents3: '',
        name_aditional_legal_documents3: '',
        date_aditional_legal_documents3: '',
        aditional_financial_documents1: '',
        name_aditional_financial_documents1: '',
        date_aditional_financial_documents1: '',
        aditional_financial_documents2: '',
        name_aditional_financial_documents2: '',
        date_aditional_financial_documents2: '',
        aditional_financial_documents3: '',
        name_aditional_financial_documents3: '',
        date_aditional_financial_documents3: '',
        is_active: '',
    });


    useEffect(() => {
        console.log(url)
        getDetailCompany(url)
            .then(data => {
                setCompany({
                    name: data.name,
                    nit: data.nit,
                    logo: data.logo,
                    certificate_existence: data.certificate_existence,
                    date_certificate_existence: data.date_certificate_existence,
                    certificate_proponent: data.certificate_proponent,
                    date_certificate_proponent: data.date_certificate_proponent,
                    operating_income: data.operating_income,
                    date_operating_income: data.date_operating_income,
                    fin_docs: data.fin_docs,
                    date_fin_docs: data.date_fin_docs,
                    tax_return: data.tax_return,
                    date_tax_return: data.date_tax_return,
                    shareholder_structure: data.shareholder_structure,
                    date_shareholder_structure: data.date_shareholder_structure,
                    disability_certificate: data.disability_certificate,
                    date_disability_certificate: data.date_disability_certificate,
                    acct_docs1: data.acct_docs1,
                    date_acct_docs1: data.date_acct_docs1,
                    acct_docs2: data.acct_docs2,
                    date_acct_docs2: data.date_acct_docs2,
                    fiscal_review_documents: data.fiscal_review_documents,
                    date_fiscal_review_documents: data.date_fiscal_review_documents,
                    social_security_certificate: data.social_security_certificate,
                    date_social_security_certificate: data.date_social_security_certificate,
                    rut: data.rut,
                    date_rut: data.date_rut,
                    k_residual: data.k_residual,
                    date_k_residual: data.date_k_residual,
                    aditional_legal_documents1: data.aditional_legal_documents1,
                    name_aditional_legal_documents1: data.name_aditional_legal_documents1,
                    date_aditional_legal_documents1: data.date_aditional_legal_documents1,
                    aditional_legal_documents2: data.aditional_legal_documents2,
                    name_aditional_legal_documents2: data.name_aditional_legal_documents2,
                    date_aditional_legal_documents2: data.date_aditional_legal_documents2,
                    aditional_legal_documents3: data.aditional_legal_documents3,
                    name_aditional_legal_documents3: data.name_aditional_legal_documents3,
                    date_aditional_legal_documents3: data.date_aditional_legal_documents3,
                    aditional_financial_documents1: data.aditional_financial_documents1,
                    name_aditional_financial_documents1: data.name_aditional_financial_documents1,
                    date_aditional_financial_documents1: data.date_aditional_financial_documents1,
                    aditional_financial_documents2: data.aditional_financial_documents2,
                    name_aditional_financial_documents2: data.name_aditional_financial_documents2,
                    date_aditional_financial_documents2: data.date_aditional_financial_documents2,
                    aditional_financial_documents3: data.aditional_financial_documents3,
                    name_aditional_financial_documents3: data.name_aditional_financial_documents3,
                    date_aditional_financial_documents3: data.date_aditional_financial_documents3,
                    is_active: data.is_active,
                });
            });
    }, [isOpen]);

    return (
        <div className="dark">
            <Button color='warning' variant='solid' onPress={onOpen}>Detalles</Button>
            <Modal
                backdrop="blur"
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                size="5xl"
                scrollBehavior={'outside'}
                classNames={{
                    body: "py-6",
                    backdrop: "dark",
                    base: "border-[#292f46]",
                    header: "border-b-[1px] border-[#292f46]",
                    footer: "border-t-[1px] border-[#292f46",
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <div>
                            <ModalHeader className="flex flex-row gap-1">
                                <img src={company.logo} className="w-16 h-16" />
                                <head className="flex flex-col">
                                    {company.name}
                                    <p className="flex flex-col gap-1 text-sm">NIT: {company.nit}</p>
                                </head>
                            </ModalHeader>

                            <ModalBody>
                                <Tabs aria-label="Options" className='' color='warning' fullWidth='true'>
                                    <Tab key="legal" title="Información legal" className=''>
                                        <Table aria-label="Detalles de la empresa">
                                            <TableHeader >
                                                <TableColumn key='descripción'>DESCRIPCIÓN</TableColumn>
                                                <TableColumn key='valor'>VALOR</TableColumn>
                                                <TableColumn key='fecha'>FECHA DE ACTUALIZACIÓN</TableColumn>
                                            </TableHeader>
                                            <TableBody>
                                                <TableRow key='certificate_existence'>
                                                    <TableCell>CERTIFICADO DE EXISTENCIA</TableCell>
                                                    <TableCell> {company.certificate_existence ? <a target="_blank" rel="noopener noreferrer" href={company.certificate_existence}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_certificate_existence}</TableCell>
                                                </TableRow>
                                                <TableRow key='certificate_proponent'>
                                                    <TableCell>CERTIFICADO DE PROPONENTE</TableCell>
                                                    <TableCell>{company.certificate_proponent ? <a target="_blank" rel="noopener noreferrer" href={company.certificate_proponent}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_certificate_proponent}</TableCell>
                                                </TableRow>
                                                <TableRow key='shareholder_structure'>
                                                    <TableCell>COMPOSICIÓN ACCIONARIA</TableCell>
                                                    <TableCell>{company.shareholder_structure ? <a target="_blank" rel="noopener noreferrer" href={company.shareholder_structure}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_shareholder_structure}</TableCell>
                                                </TableRow>
                                                <TableRow key='disability_certificate'>
                                                    <TableCell>CERTIFICADO DE DISCAPACIDAD</TableCell>
                                                    <TableCell>{company.disability_certificate ? <a target="_blank" rel="noopener noreferrer" href={company.disability_certificate}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_disability_certificate}</TableCell>
                                                </TableRow>
                                                <TableRow key='social_security_certificate'>
                                                    <TableCell>CERTIFICADO PAGO DE APORTES</TableCell>
                                                    <TableCell>{company.social_security_certificate ? <a target="_blank" rel="noopener noreferrer" href={company.social_security_certificate}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_social_security_certificate}</TableCell>
                                                </TableRow>
                                                <TableRow key='rut'>
                                                    <TableCell>RUT</TableCell>
                                                    <TableCell>{company.rut ? <a target="_blank" rel="noopener noreferrer" href={company.rut}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_rut}</TableCell>
                                                </TableRow>
                                                {company.name_aditional_legal_documents1 ?
                                                    <TableRow key='name_aditional_legal_documents1'>
                                                        <TableCell>{company.name_aditional_legal_documents1.toUpperCase()}</TableCell>
                                                        <TableCell>{company.aditional_legal_documents1 ? <a target="_blank" rel="noopener noreferrer" href={company.aditional_legal_documents1}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                        <TableCell>{company.date_aditional_legal_documents1}</TableCell>
                                                    </TableRow> : null}
                                                {company.name_aditional_legal_documents2 ?
                                                    <TableRow key='name_aditional_legal_documents2'>
                                                        <TableCell>{company.name_aditional_legal_documents2.toUpperCase()}</TableCell>
                                                        <TableCell>{company.aditional_legal_documents2 ? <a target="_blank" rel="noopener noreferrer" href={company.aditional_legal_documents2}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                        <TableCell>{company.date_aditional_legal_documents2}</TableCell>
                                                    </TableRow> : null}
                                                {company.name_aditional_legal_documents3 ?
                                                    <TableRow key='name_aditional_legal_documents3'>
                                                        <TableCell>{company.name_aditional_legal_documents3.toUpperCase()}</TableCell>
                                                        <TableCell>{company.aditional_legal_documents3 ? <a target="_blank" rel="noopener noreferrer" href={company.aditional_legal_documents3}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                        <TableCell>{company.date_aditional_legal_documents3}</TableCell>
                                                    </TableRow> : null}
                                            </TableBody>
                                        </Table>
                                    </Tab>
                                    <Tab key="financial" title="Información financiera">
                                        <Table aria-label="Detalles de la empresa">
                                            <TableHeader >
                                                <TableColumn key='descripción'>DESCRIPCIÓN</TableColumn>
                                                <TableColumn key='valor'>VALOR</TableColumn>
                                                <TableColumn key='fecha'>FECHA DE ACTUALIZACIÓN</TableColumn>
                                            </TableHeader>
                                            <TableBody>
                                                <TableRow key='fin_docs'>
                                                    <TableCell>ESTADOS FINANCIEROS</TableCell>
                                                    <TableCell> {company.fin_docs ? <a target="_blank" rel="noopener noreferrer" href={company.fin_docs}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_fin_docs}</TableCell>
                                                </TableRow>
                                                <TableRow key='operating_income'>
                                                    <TableCell>MAYOR INGRESO OPERACIONAL</TableCell>
                                                    <TableCell>{company.operating_income ? <a target="_blank" rel="noopener noreferrer" href={company.operating_income}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_operating_income}</TableCell>
                                                </TableRow>
                                                < TableRow key='tax_return'>
                                                    <TableCell>DECLARACIÓN DE RENTA</TableCell>
                                                    <TableCell>{company.tax_return ? <a target="_blank" rel="noopener noreferrer" href={company.tax_return}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_tax_return}</TableCell>
                                                </TableRow>
                                                <TableRow key='acct_docs1'>
                                                    <TableCell>DOCUMENTOS CONTADOR 1</TableCell>
                                                    <TableCell>{company.acct_docs1 ? <a target="_blank" rel="noopener noreferrer" href={company.acct_docs1}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_acct_docs1}</TableCell>
                                                </TableRow>
                                                <TableRow key='acct_docs2'>
                                                    <TableCell>DOCUMENTOS CONTADOR 2</TableCell>
                                                    <TableCell>{company.acct_docs2 ? <a target="_blank" rel="noopener noreferrer" href={company.acct_docs2}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_acct_docs2}</TableCell>
                                                </TableRow>
                                                <TableRow key='fiscal_review_documents' >
                                                    <TableCell>DOCUMENTOS REVISOR FISCAL</TableCell>
                                                    <TableCell>{company.fiscal_review_documents ? <a target="_blank" rel="noopener noreferrer" href={company.fiscal_review_documents}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_fiscal_review_documents}</TableCell>
                                                </TableRow>
                                                <TableRow key='k_residual'>
                                                    <TableCell>K RESIDUAL</TableCell>
                                                    <TableCell>{company.k_residual ? <a target="_blank" rel="noopener noreferrer" href={company.k_residual}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                    <TableCell>{company.date_k_residual}</TableCell>
                                                </TableRow>
                                                {company.name_aditional_financial_documents1 ?
                                                    <TableRow key='name_aditional_financial_documents1'>
                                                        <TableCell>{company.name_aditional_financial_documents1.toUpperCase()}</TableCell>
                                                        <TableCell>{company.aditional_financial_documents1 ? <a target="_blank" rel="noopener noreferrer" href={company.aditional_financial_documents1}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                        <TableCell>{company.date_aditional_financial_documents1}</TableCell>
                                                    </TableRow> : null}
                                                {company.name_aditional_financial_documents2 ?
                                                    <TableRow key='name_aditional_financial_documents2'>
                                                        <TableCell>{company.name_aditional_financial_documents2.toUpperCase()}</TableCell>
                                                        <TableCell>{company.aditional_financial_documents2 ? <a target="_blank" rel="noopener noreferrer" href={company.aditional_financial_documents2}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                        <TableCell>{company.date_aditional_financial_documents2}</TableCell>
                                                    </TableRow> : null}
                                                {company.name_aditional_financial_documents3 ?
                                                    <TableRow key='name_aditional_financial_documents3'>
                                                        <TableCell>{company.name_aditional_financial_documents3.toUpperCase()}</TableCell>
                                                        <TableCell>{company.aditional_financial_documents3 ? <a target="_blank" rel="noopener noreferrer" href={company.aditional_financial_documents3}><PdfIcon /></a> : <NoPdfIcon></NoPdfIcon>}</TableCell>
                                                        <TableCell>{company.date_aditional_financial_documents3}</TableCell>
                                                    </TableRow> : null}
                                            </TableBody>
                                        </Table>
                                    </Tab>
                                </Tabs>

                            </ModalBody>


                            <ModalFooter>
                                <Button color="warning" variant="solid" onPress={onClose}>
                                    Cerrar
                                </Button>
                            </ModalFooter>
                        </div>
                    )}
                </ModalContent>
            </Modal>
        </div >
    );
}