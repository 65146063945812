const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const getContratos = async (params) => {
	const myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', `JWT ${localStorage.getItem('access')}`);
	myHeaders.append('Accept', 'application/json');

	const params_url = `?municipio=${params.city}&empresa=${params.company}&objeto=${params.key}&departamento=${params.state}&tipo_de_obra=${params.type}&valor_ejecutado__lte=${params.valor_ejecutado__lte}&valor_ejecutado__gte=${params.valor_ejecutado__gte}&year=${params.year}`;
	const url = `/api/contratos/`;
	const query = `${REACT_APP_API_URL}${url}${params_url}`;
	const response = await fetch(query, {
		method: 'GET',
		headers: myHeaders,
	});
	const contratos = response.json();
	return contratos
}

export const detailContract = async (urlContract) => {
	const myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', `JWT ${localStorage.getItem('access')}`);
	myHeaders.append('Accept', 'application/json');

	const url = `${REACT_APP_API_URL}${urlContract}/`;
	const response = await fetch(url, {
		method: 'GET',
		headers: myHeaders,
	});
	const contrato = response.json();
	return contrato
}

export const updateContratos = async (url) => {
	const myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', `JWT ${localStorage.getItem('access')}`);
	myHeaders.append('Accept', 'application/json');

	const response = await fetch(url, {
		method: 'GET',
		headers: myHeaders,
	});
	const contratos = response.json();
	return contratos
}

export const gereateReport = async (params) => {

	const myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('Authorization', `JWT ${localStorage.getItem('access')}`);
	myHeaders.append('Accept', 'application/json');

	const params_url = `?municipio=${params.city}&empresa=${params.company}&objeto=${params.key}&departamento=${params.state}&tipo_de_obra=${params.type}&valor_ejecutado__lte=${params.valor_ejecutado__lte}&valor_ejecutado__gte=${params.valor_ejecutado__gte}&year=${params.year}`;

	const urlReport = `/contratos/report-contracts`;
	const query = `${REACT_APP_API_URL}${urlReport}/${params_url}`;

	try {
		const response = await fetch(query, {
			method: 'GET',
			headers: myHeaders,
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		// Corrección en el manejo de la respuesta
		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob);
		// Crear un enlace para descargar el archivo
		const a = document.createElement('a');
		a.href = url;
		a.download = 'reporte_contratos.xlsx'; // Nombre del archivo
		document.body.appendChild(a);
		a.click();

		// Limpieza
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	} catch (error) {
		console.error('Error fetching report:', error);
	}
};