import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import "./input.css"

import Home from "./pages/home"
import Contracts from "./pages/contracts"

import store from './store';
import Layout from './context/Layout'
import { Provider } from 'react-redux';


function App() {
  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/contracts" element={<Contracts />}></Route>
          </Routes>
        </Layout>
      </Router>
    </Provider>
  );
}

export default App;