import React from 'react';

function ArmelcoLogoName() {
    return (
        <svg width="400px" height="400px" viewBox="0 0 186 223">
            <title>Armelco logo con nombre</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Home" transform="translate(-627.000000, -242.000000)" fill="#1f1f1f">
                    <g id="Group-23-Copy" transform="translate(627.000000, 242.000000)">
                        <path d="M8.83657006,199.451889 L0,222.451889 L6.31172194,222.451889 L7.89403446,218.441866 L16.1402251,218.441866 L17.6237427,222.451889 L24,222.451889 L15.3490688,199.451889 L8.83657006,199.451889 Z M12.0513893,206.562561 L14.5252465,213.893397 L9.54327258,213.893397 L12.0513893,206.562561 Z" id="Fill-1" ></path>
                        <g id="Group-22" transform="translate(0.000391, 0.000000)">
                            <path d="M36.6330126,206.345108 C36.6330126,205.542308 36.3399454,204.867048 35.7538109,204.317764 C35.2028445,203.801343 34.3127017,203.543915 33.0872899,203.543915 L31.9939538,203.543915 L31.9939538,209.163515 L33.0872899,209.163515 C34.3252059,209.163515 35.2137857,208.906087 35.7538109,208.387319 C36.3399454,207.827862 36.6330126,207.145561 36.6330126,206.345108 L36.6330126,206.345108 Z M44.870937,221.641343 L37.5919286,221.641343 L31.9939538,212.980336 L31.9939538,221.641343 L26.1326092,221.641343 L26.1326092,199.080785 L35.2458277,199.080785 C37.9092227,199.080785 39.879416,199.877326 41.1564076,201.475884 C42.1903487,202.765371 42.7084916,204.280989 42.7084916,206.022736 C42.7084916,208.017217 42.114542,209.60795 40.9266429,210.798848 C40.1662311,211.560178 39.1213487,212.087554 37.7888697,212.386452 L44.870937,221.641343 Z" id="Fill-2" ></path>
                            <polygon id="Fill-4" points="74.5691975 221.641187 68.709416 221.641187 67.0424496 208.65085 61.6015588 221.641187 59.2757773 221.641187 54.081063 208.65085 52.1671387 221.641187 46.3237689 221.641187 50.1523992 199.080629 55.9488782 199.080629 60.5058782 211.112457 65.3262479 199.080629 71.1680546 199.080629"></polygon>
                            <polygon id="Fill-6" points="91.1114748 221.641187 78.2837269 221.641187 78.2837269 199.080629 91.1114748 199.080629 91.1114748 203.973327 84.1458529 203.973327 84.1458529 207.824576 90.7324412 207.824576 90.7324412 212.716492 84.1458529 212.716492 84.1458529 216.748488 91.1114748 216.748488"></polygon>
                            <polygon id="Fill-8" points="107.718618 221.641187 94.8260042 221.641187 94.8260042 199.080629 100.686567 199.080629 100.686567 216.748488 107.718618 216.748488"></polygon>
                            <path d="M126.951174,221.112403 C125.036468,221.873733 123.265561,222.254006 121.635325,222.254006 C118.795308,222.254006 116.254611,221.344792 114.007762,219.526364 C111.146645,217.202469 109.716476,214.171234 109.716476,210.435789 C109.716476,206.688606 111.146645,203.653459 114.007762,201.328782 C116.254611,199.510354 118.813283,198.599575 121.685342,198.599575 C123.24993,198.599575 125.005208,198.981413 126.951174,199.741178 L126.951174,206.764505 C126.401771,206.089245 125.837518,205.577519 125.265451,205.235585 C124.219006,204.602578 123.093628,204.286466 121.892443,204.286466 C120.370838,204.286466 119.048518,204.745768 117.924703,205.662806 C116.503132,206.822406 115.792737,208.413922 115.792737,210.435789 C115.792737,212.446701 116.503132,214.030392 117.924703,215.19234 C119.048518,216.108596 120.370838,216.567115 121.892443,216.567115 C123.093628,216.567115 124.219006,216.251785 125.265451,215.619561 C125.82814,215.286234 126.388485,214.773726 126.951174,214.087512 L126.951174,221.112403 Z" id="Fill-10" ></path>
                            <path d="M148.529753,210.43618 C148.529753,208.845447 148.02177,207.468324 147.006585,206.309506 C145.706148,204.828317 144.078257,204.088896 142.123694,204.088896 C140.776366,204.088896 139.557207,204.467605 138.465434,205.222675 C137.375224,205.980875 136.595274,206.988678 136.132618,208.24765 C135.866123,208.966727 135.73561,209.695976 135.73561,210.43618 C135.73561,211.960405 136.241249,213.325008 137.257997,214.528426 C138.516232,216.019787 140.137871,216.765468 142.123694,216.765468 C144.110299,216.765468 145.735845,216.026047 147.006585,214.544075 C148.02177,213.363348 148.529753,211.993268 148.529753,210.43618 M154.60445,210.43618 C154.60445,213.500278 153.586921,216.187233 151.550299,218.50252 C149.226862,221.134703 146.085963,222.451576 142.123694,222.451576 C138.173148,222.451576 135.036156,221.134703 132.714282,218.50252 C130.677661,216.187233 129.65935,213.500278 129.65935,210.43618 C129.65935,209.036366 129.934442,207.601341 130.485408,206.130324 C131.036375,204.659306 131.771778,203.399552 132.697871,202.354191 C135.018182,199.720443 138.161425,198.402005 142.123694,198.402005 C146.085963,198.402005 149.226862,199.720443 151.550299,202.354191 C153.586921,204.667131 154.60445,207.361127 154.60445,210.43618" id="Fill-12" ></path>
                            <path d="M165.370412,218.120134 C165.370412,219.920566 164.581084,221.095032 163.00321,221.641187 C162.451462,221.833671 161.858294,221.93226 161.224487,221.93226 C159.894353,221.93226 158.660345,221.527731 157.523244,220.721018 L158.708017,218.490236 C159.540328,219.244524 160.356227,219.620885 161.152588,219.620885 C161.511303,219.620885 161.814529,219.537945 162.065395,219.369716 C162.356899,219.17958 162.503042,218.912762 162.503042,218.565352 C162.503042,218.250804 162.341269,217.986334 162.019286,217.778201 C161.77858,217.620145 161.377664,217.458959 160.807941,217.296208 C160.12021,217.093552 159.689597,216.953492 159.512975,216.874464 C159.232412,216.756313 158.993269,216.615471 158.795546,216.455067 C158.227387,215.987941 157.944479,215.282166 157.944479,214.336176 C157.944479,213.347934 158.237546,212.530267 158.824462,211.880829 C159.512193,211.117934 160.423437,210.735313 161.56679,210.735313 C162.716395,210.735313 163.838647,211.024822 164.939798,211.600709 L163.834739,213.754811 C163.23063,213.280643 162.621832,213.043559 162.006782,213.043559 C161.710588,213.043559 161.444092,213.119457 161.209639,213.272036 C160.943924,213.443394 160.811067,213.674218 160.811067,213.960597 C160.811067,214.242281 160.986908,214.485625 161.334681,214.689064 C161.490983,214.782959 161.959109,214.944927 162.73984,215.174187 C163.719076,215.460566 164.415403,215.867443 164.832731,216.393253 C165.190664,216.840818 165.370412,217.416706 165.370412,218.120134" id="Fill-14" ></path>
                            <path d="M172.835264,217.678516 L171.689566,214.285004 L170.529802,217.678516 L172.835264,217.678516 Z M177.221894,221.640874 L174.269339,221.640874 L173.583953,219.78567 L169.764701,219.78567 L169.033987,221.640874 L166.111129,221.640874 L170.201566,210.991646 L173.216642,210.991646 L177.221894,221.640874 Z" id="Fill-16" ></path>
                            <path d="M185.816501,218.120134 C185.816501,219.920566 185.027173,221.095032 183.448518,221.641187 C182.898333,221.833671 182.305165,221.93226 181.670576,221.93226 C180.342005,221.93226 179.107215,221.527731 177.969333,220.721018 L179.154106,218.490236 C179.987198,219.244524 180.802316,219.620885 181.597896,219.620885 C181.958173,219.620885 182.2614,219.537945 182.511484,219.369716 C182.804551,219.17958 182.949131,218.912762 182.949131,218.565352 C182.949131,218.250804 182.788139,217.986334 182.464593,217.778201 C182.22545,217.620145 181.823753,217.458959 181.25403,217.296208 C180.567081,217.093552 180.135686,216.953492 179.959845,216.874464 C179.678501,216.756313 179.439358,216.615471 179.241635,216.455067 C178.674257,215.987941 178.390568,215.282166 178.390568,214.336176 C178.390568,213.347934 178.684417,212.530267 179.271333,211.880829 C179.955938,211.117934 180.871089,210.735313 182.014442,210.735313 C183.161703,210.735313 184.286299,211.024822 185.38745,211.600709 L184.28161,213.754811 C183.677501,213.280643 183.067139,213.043559 182.452871,213.043559 C182.155114,213.043559 181.890963,213.119457 181.657291,213.272036 C181.390013,213.443394 181.257938,213.674218 181.257938,213.960597 C181.257938,214.242281 181.431434,214.485625 181.78077,214.689064 C181.936291,214.782959 182.405198,214.944927 183.185148,215.174187 C184.163602,215.460566 184.862274,215.867443 185.278039,216.393253 C185.637534,216.840818 185.816501,217.416706 185.816501,218.120134" id="Fill-18" ></path>
                            <path d="M128.447067,176.661382 L116.057748,176.661382 L96.906,138.30773 L103.317529,125.469189 L128.447067,176.661382 Z M67.8368571,176.661382 L55.1755714,176.661382 L112.702714,59.9924772 L170.968387,176.661382 L158.801798,176.661382 L113.317765,85.5874 L67.8368571,176.661382 Z M70.5776218,85.5874 L25.0935882,176.661382 L14.350916,176.661382 L71.8897815,61.450193 L86.9581261,92.1459474 L80.4457815,105.350677 L70.5776218,85.5874 Z M107.726042,50.0263333 L91.9074454,82.1023404 L76.8734874,51.4691825 L92.6592605,19.855607 L107.726042,50.0263333 Z M153.318706,185.54226 L185.319303,185.54226 L92.6592605,5.68434189e-14 L67.6008403,50.1781298 L66.9717227,51.4355368 L0,185.54226 L30.5751176,185.54226 L70.5776218,105.442225 L75.5206891,115.341077 L40.9051513,185.54226 L73.3183866,185.54226 L91.9457395,148.237098 L110.577,185.54226 L142.690134,185.54226 L108.300454,115.491309 L113.317765,105.442225 L153.318706,185.54226 Z" id="Fill-20" ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default ArmelcoLogoName;
