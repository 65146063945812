import React from 'react'

import ConstractsHeader from '../components/ContractsHeader'
import FilterContracts from '../components/FilterContracts'
import ContentContracts from '../components/ContentContracts'
import { getContratos } from '../services/contratos'
import { Tabs, Tab } from "@nextui-org/react";

import '../components/styles/tabs.css'
import CompaniesContent from '../components/CompaniesContent'

class Contracts extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			contratos: {},
			flag2: false,
			formdata: {}
		};
	}

	loadContracts = (formdata) => {
		getContratos(formdata)
			.then(data => {
				this.setState({ contratos: data });
			});
	}

	handleSearch = (formdata) => {
		this.setState({ flag2: !this.state.flag2 });
		this.setState({ formdata: formdata });
		this.loadContracts(formdata);
	}

	render() {
		return (
			< >
				<ConstractsHeader />
				<Tabs aria-label="Options" className='tabs dark  text-foreground bg-background' color='warning' size='lg' radius='sm'>
					<Tab key="contracts" title="Contratos" className='tab'>
						<FilterContracts handleSearch={this.handleSearch} />
						<ContentContracts
							data={this.state.contratos}
							formdata={this.state.formdata}>
						</ContentContracts>
					</Tab>
					<Tab key="companies" title="Empresas" className='tab'>
						<CompaniesContent />
					</Tab>
				</Tabs>
			</>
		)
	}
}

export default Contracts
