import React from "react";
export function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const formatNumber = (number) => {
    const num = Math.round(number);
    const formatter = num.toLocaleString("es", {
        style: "currency",
        currency: "COP",
        maximumFractionDigits: 0, // Establecer cero decimales
    });
    return formatter
};
