import { React, useState, useEffect } from 'react'

import './styles/contracts.css'
import { updateContratos, gereateReport } from '../services/contratos'

import { connect } from 'react-redux';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Pagination, Button } from "@nextui-org/react";
import { formatNumber } from './utils'
import ContractDetail from "./contract-detail.jsx";
import { ScrollShadow } from "@nextui-org/react";


function ContractsContent({ isAuthenticated, data, formdata }) {
	const [newUrl, setNewUrl] = useState('');
	const [page, setPage] = useState(1);



	const [contratos, setContratos] = useState({
		count: data.count,
		next: data.next,
		previous: data.previous,
		results: data.results
	});

	const getDetailUrl = (contrat) => {
		const urlDetail = contrat.url.split('/')
		const url = urlDetail[urlDetail.length - 2]
		return "/api/contratos/" + url
	};

	const handleClickPaginator = (page) => {
		if (contratos.next != null) {
			setNewUrl(contratos.next.replace(/page=\d+/, `page=${page}`))
		}
		else if (contratos.previous != null) {
			setNewUrl(contratos.previous.replace(/page=\d+/, `page=${page}`))
		}
		else {
			setNewUrl('')
		}
	};

	useEffect(() => {
		setContratos({
			count: data.count,
			next: data.next,
			previous: data.previous,
			results: data.results
		});
	}, [data]);

	useEffect(() => {
		if (newUrl != '') {
			updateContratos(newUrl)
				.then(update_data => {
					setContratos({
						count: update_data.count,
						next: update_data.next,
						previous: update_data.previous,
						results: update_data.results
					});
				});
		}
	}, [newUrl]);

	return (
		<div className='flex justify-center w-full bg-white'>

			<ScrollShadow orientation="horizontal" className="max-w-[400px] max-h-[300px]">

				<div className="table-content">

					<Table
						aria-label="Example table with client side pagination"
						topContent={
							<div className="flex w-full justify-end md:justify-start sm:justify-start">
								<Button
									onPress={() => gereateReport(formdata)}
									color="default" auto>Descargar</Button>
							</div>
						}
						bottomContent={
							<div className="flex w-full justify-center">
								<Pagination
									isCompact
									showControls
									showShadow
									color="warning"
									page={page}
									total={contratos.count ? Math.ceil(contratos.count / 10) : 1}
									onChange={(page) => handleClickPaginator(page)}

								/>
							</div>
						}
						classNames='h-80 min-w-content'
					>
						<TableHeader >
							<TableColumn key="empresa">EMPRESA</TableColumn>
							<TableColumn key="year">AÑO</TableColumn>
							<TableColumn key="objeto">OBJETO</TableColumn>
							<TableColumn key="valor">VALOR</TableColumn>
							<TableColumn key="status">PARTICIPACIÓN</TableColumn>
							<TableColumn key="archivos">Archivos</TableColumn>
						</TableHeader>
						{isAuthenticated ?
							<TableBody emptyContent={"Realiza una busqueda"}>
								{(typeof (contratos.results) == "object") ? Object.values(contratos.results).map((contrato, index) => {
									const urlDetail = getDetailUrl(contrato);
									return (

										<TableRow key={contrato.id}>
											<TableCell  >{contrato.empresa}</TableCell>
											<TableCell >{contrato.year}</TableCell>
											<TableCell ><p className='table-h-cell'>{contrato.objeto}</p></TableCell>
											<TableCell className='cell-align-right'>{formatNumber(contrato.valor_ejecutado)}</TableCell>
											<TableCell className='cell-align-right'>{parseInt(contrato.porcentaje_participacion * 100)}%</TableCell>
											<TableCell ><ContractDetail url_contract={urlDetail} /> </TableCell>
										</TableRow>

									)
								})
									: []
								}
							</TableBody> : <TableBody emptyContent={"Debes iniciar sesión."}></TableBody>}
					</Table>
				</div>
			</ScrollShadow>

		</div >
	)
}


const mapStateToProps = (state, ownProps) => ({
	isAuthenticated: state.auth.isAuthenticated,
	data: ownProps.data,
	formdata: ownProps.formdata,
});

export default connect(mapStateToProps, null)(ContractsContent);


