import React from 'react'
import '../input.css'
import '../components/styles/home-section.css'
import LandingHeader from '../components/LandingHearder'
import HomeSection from '../components/HomeSection'
import AboutSection from '../components/AboutSection'
import ProjectSection from '../components/ProjectSection'


class Home extends React.Component {

	render() {
		return (
			<>
				<LandingHeader id='landing-header' />
				<main >
					<div className='scroll-slider'>
						<div className='section-scroll' id='home'>
							<HomeSection />
						</div>
						<div className='section-scroll' id='about'>
							<AboutSection />
						</div>
						<div className='section-scroll' id='projects'>
							<ProjectSection />
						</div>
					</div>
				</main>
			</>
		)
	}
}

export default Home