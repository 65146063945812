import React from 'react';
import './styles/customs.css';

function LandingHeader() {
    return (
        <header
            id='landing-header'
            className='py-3 md:px-10 flex items-center fixed top-0 w-full justify-between z-40'
        >
            <div className='flex flex-grow basis-0' />
            <nav className='nav-visibility'>
                <ul className='flex text-sm'>
                    <li className='rounded'>
                        <a href='#home' className='px-4 py-2'>
                            Inicio
                        </a>
                    </li>
                    <li className='rounded'>
                        <a href='#about' className='px-4 py-2'>
                            Sobre Nosotros
                        </a>
                    </li>
                    <li className='rounded'>
                        <a href='#projects' className='px-4 py-2'>
                            Proyectos
                        </a>
                    </li>
                </ul>
            </nav>
            <nav className='flex flex-grow basis-0 justify-end'>
                <ul>
                    <li className='rounded sign-in'>
                        <a href='/contracts' className='inline-block px-4 py-2'>
                            Ingresar
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default LandingHeader;
