import React, { useState } from 'react'

import './styles/contracts.css'
import PropTypes from 'prop-types'
import { Input, Button, Select, SelectItem } from "@nextui-org/react";


function FilterContracts(props) {

	const [formData, setFormData] = useState({
		numero: '',
		key: '',
		year: '',
		state: '',
		city: '',
		valor_ejecutado__gte: '',
		valor_ejecutado__lte: '',
		type: '',
		company: '',
	});

	const clearForm = () => {
		document.getElementById("filterform").reset()
	};
	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });


	const { handleSearch } = props

	const categorias = [
		'CONSTRUCCIÓN DE EDIFICACIONES',
		'MANTENIMIENTO Y REMODELACIÓN DE EDIFICACIONES',
		'INFRAESTRUCTURA VIAL',
		'OBRAS DE URBANISMO O ESPACIO PUBLICO',
		'ADECUACION Y MOVIMIENTO DE TIERRAS',
		'CONSULTORIA Y DISEÑO',
		'INTERVENTORIA EN INFRAESTRUCTURA VIAL',
		'CONSTRUCCIÓN DE ALCANTARILLADOS Y ACUEDUCTOS',
		'INFRAESTRUCTURA ELÉCTRICA',
		'SUMINISTRO'
	]


	return (
		<div className='dark flex text-foreground bg-background w-full m-0 p-0 justify-center'>
			<div className='justify-center relative content-bg'>
				<div>
					<h1 className='filter-title'>CONTRATOS</h1>
				</div>
				<form id='filterform' className='flex flex-wrap gap-4 justify-center'>
					<Input
						name='key'
						type="text"
						label="Palabra clave"
						placeholder="Edificio ..."
						labelPlacement={'outside'}
						onChange={e => onChange(e)}
						className='w-4/5'
					/>
					<Input
						name='year'
						type="text"
						label="Año"
						labelPlacement={'outside'}
						placeholder="2018"
						onChange={e => onChange(e)}
						className='w-4/5'
					/>
					<Input
						name='state'
						type="text"
						label="Departamento"
						labelPlacement={'outside'}
						placeholder="Huila"
						onChange={e => onChange(e)}
						className='w-4/5'
					/>
					<Input
						name='valor_ejecutado__gte'
						type="text"
						label="Cuantia desde"
						placeholder="1.000.000.000"
						labelPlacement={'outside'}
						onChange={e => onChange(e)}
						className='w-4/5'
					/>
					<Select
						name="type"
						label="Categoría"
						placeholder="Elige una categoría"
						labelPlacement="outside"
						onChange={e => setFormData({ ...formData, type: categorias[e.target.value] })}
						className='fixed-width-select'
					>
						{categorias.map((categoria, index) => (
							<SelectItem key={index} value={categoria} textValue={categoria}>
								{categoria}
							</SelectItem>
						))}
					</Select>

					<Input
						name='company'
						type="text"
						label="Empresa"
						labelPlacement={'outside'}
						placeholder="Armelco"
						onChange={e => onChange(e)}
						className='w-4/5'
					/>
					<Input
						name='city'
						type="text"
						label="Ciudad"
						labelPlacement={'outside'}
						placeholder="Neiva"
						onChange={e => onChange(e)}
						className='w-4/5'
					/>
					<Input
						name='valor_ejecutado__lte'
						type="text"
						label="Cuantia hasta"
						labelPlacement={'outside'}
						placeholder="5.000.000.000"
						onChange={e => onChange(e)}
						className='w-4/5'
					/>
				</form >
				<div className='flex flex-row gap-4 justify-end filter-btn'>
					<Button
						type="reset"
						variant="bordered"
						color="warning"
						className="float-right"
						onClick={clearForm}
					>
						Limpiar
					</Button>

					<Button
						type="submit"
						variant="bordered"
						color='warning'
						className='float-right'
						onClick={() => handleSearch(formData)}>
						Buscar
					</Button>
				</div>
			</div >


		</div >

	)
}


FilterContracts.propTypes = {
	handleSearch: PropTypes.func.isRequired
}

export default FilterContracts


