import React, { useState } from 'react'
import { connect } from 'react-redux';
import { login, logout } from '../actions/auth';

import { Button } from "@nextui-org/react";
import ArmelcoLogo from "../images/ArmelcoLogo";

import { Input } from "@nextui-org/react";
import { EyeFilledIcon } from "./EyeFilledIcon";
import { EyeSlashFilledIcon } from "./EyeSlashFilledIcon";

function NavBlack({ logout, login, isAuthenticated }) {
	const [isVisible, setIsVisible] = React.useState(false);
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});

	const { email, password } = formData;
	const toggleVisibility = () => setIsVisible(!isVisible);

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = e => {
		e.preventDefault();
		login(email, password);
	};

	return (
		<header className='dark text-foreground bg-background  py-3 md:px-10 flex items-center fixed top-0 w-full justify-between z-40'>

			<nav className='flex flex-row align-middle  content-center'>
				<a href='/' className='flex flex-row logo-nav'>
					<ArmelcoLogo
						color='#FFFFFF'
						size='68px'
						opacity='1' />
					<p className="font-bold text-inherit">ARMELCO SAS</p>
				</a>
			</nav>
			<nav className='flex'>
				{isAuthenticated ?
					<Button onClick={logout} color='warning' variant="bordered" className='btn-logout'>
						Salir
					</Button> :
					<form onSubmit={e => onSubmit(e)} className='max-w-md dark  gap-3 login-form'>
						<Input
							key={'outside'}
							name='email'
							type="email"
							label="Correo"
							labelPlacement={'outside'}
							size='md'
							onChange={e => onChange(e)}
						/>

						<Input
							label="Contraseña"
							name='password'
							key={'outside'}
							size='md'
							labelPlacement={'outside'}
							onChange={e => onChange(e)}
							endContent={
								<button className="focus:outline-none" type="button" onClick={toggleVisibility}>
									{isVisible ? (
										<EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
									) : (
										<EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
									)}
								</button>
							}
							type={isVisible ? "text" : "password"}
						/>
						<Button type="submit" color='warning' variant="bordered" className='px-4'>
							Iniciar
						</Button>
					</form>}
			</nav>
		</header>
	);
};


const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated
});

const mapDispatch = { login, logout };

export default connect(mapStateToProps, mapDispatch)(NavBlack);

