const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const getValueSM = async (year) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `JWT ${localStorage.getItem('access')}`);
    myHeaders.append('Accept', 'application/json');

    const params_url = `?year=${year}`;
    const url = `/api/sm/`;
    const query = `${REACT_APP_API_URL}${url}${params_url}`;
    const response = await fetch(query, {
        method: 'GET',
        headers: myHeaders,
    });
    const sm = response.json();
    return sm
}
