import InstagramIcon from '../images/InstagramIcon';
import EmailIcon from '../images/EmailIcon';
import AddressIcon from '../images/AddressIcon';
import WhatsappIcon from '../images/WhatsappIcon';
import './styles/project-section.css';

const Footer = () => {
    return (
        <div className='w-4/5 bottom-0 absolute flex-grow footer-project'>
            <footer className='z-30 flex flex-row flex-grow flex-wrap pt-10 mt-10 footer-justify gap-4'>
                <div className="flex items-center">
                    <a href="https://www.instagram.com/armelco.sas_constructora/" target="_blank" className='flex items-center'>
                        <InstagramIcon
                            color='#000000' />
                        <p className="icon-margin">@armelco.sas_constructora</p>
                    </a>
                </div>
                <div className="flex items-center">
                    <EmailIcon
                        color='#000000' />
                    <p className="icon-margin">armelco.sas@gmail.com</p>
                </div>
                <div className="flex items-center responsive-element">
                    <a href="https://maps.app.goo.gl/dFsrDnnqJqYJaGdo6" target="_blank" className='flex items-center'>
                        <AddressIcon
                            color='#000000' />
                        <p className="icon-margin">Calle 46 No 16-24 of 403 Neiva Huila</p>
                    </a>
                </div>
                <div className="flex items-center">
                    <a href="https://wa.me/573214703461/?text=Hola Armelco SAS te escribo desde tu web" target="_blank" className='flex items-center'>
                        <WhatsappIcon
                            color='#000000' />
                        <p className="icon-margin">Escríbenos</p>
                    </a>
                </div>


            </footer>
        </div>
    );
};

export default Footer;

