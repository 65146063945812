import React from 'react';
import ArmelcoLogo from '../images/ArmelcoLogo';
import './styles/about-section.css';
import './styles/home-section.css';

function AboutSection() {
    return (
        <section className='landing-section h-screen w-screen bg-gris text-center relative overflow-hidden' data-header-color='white'>
            <div className='z-30 relative h-full flex flex-col bg-gris'>
                <header className='logo-about flex flex-col'>
                    <h1 className='sm:text-sm'>Sobre Nosotros</h1>
                    <p className='sm:text-sm'> Armelco S.A.S es una empresa con más de 20 años de experiencia en el diseño, construcción y comercialización de proyectos en el sector de las obras civiles, generando soluciones y progreso a nuestros colaboradores y clientes materializando sueños y construyendo un mejor país. Contamos con un importante récord de obra especializándonos en edificaciones institucionales, recreacionales y deportivas, al igual que en obras de saneamiento e infraestructura vial.</p>
                </header>
                <div className='flex flex-row flex-grow  justify-end'>
                    <footer className='absolute bottom-0  pr-10'>
                        <div className='flex justify-end'>
                            <ArmelcoLogo
                                color='#FFFFFF'
                                size='800px'
                                opacity='0.03' />
                        </div>
                    </footer>
                </div>
            </div>

        </section>
    );
}

export default AboutSection;
