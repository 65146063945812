import React, { useState, useEffect } from "react";
import { getCompanies, getDetailCompany } from "../services/companies";
import { Card, CardHeader, CardBody, Image } from "@nextui-org/react";
import { render } from "react-dom";
import "../components/styles/companies.css";
import CompanyDetail from "./CompanyDetail.jsx";

const CompaniesContent = () => {
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        getCompanies().then((data) => {
            setCompanies(data);
        })
    }, []);

    const getDetailUrl = (company) => {
        const urlDetail = company.url.split('/')
        const url = urlDetail[urlDetail.length - 2]
        return "/api/companies/" + url
    };

    console.log(companies)
    return (
        <section className='flex flex-col text-bold justify-center w-full'>
            <head className="flex uppercase companies-title">
                <h1 > EMPRESAS </h1>
            </head>
            <div className="flex flex-row flex-wrap gap-5 dark w-full content-companies">
                {(typeof (companies.results) == "object") ? Object.values(companies.results).map((company, index) => {
                    const urlDetail = getDetailUrl(company);
                    return (
                        <Card className="py-4 m-5 company">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                                <h1 className="text-bold uppercase text-large font-bold">{company.name}</h1>
                                <p>NIT: {company.nit}</p>
                            </CardHeader>
                            <CardBody className="overflow-visible py-2">
                                <Image
                                    alt="Card background"
                                    className="object-cover rounded-xl"
                                    src={company.logo}
                                    width={270}
                                />
                            </CardBody>
                            <span className="flex flex-row justify-end px-3">
                                <CompanyDetail url={urlDetail} />
                            </span>
                        </Card>)
                }) : null}

            </div>
        </section>
    );
};

export default CompaniesContent;
