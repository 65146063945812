import React from 'react';

function ArmelcoLogo(props) {
    const color = props.color;
    const size = props.size;
    const opacity = props.opacity;


    return (
        <svg width={size} height={size} viewBox="0 0 68 68" version="1.1" opacity={opacity}>
            <title>Armelco Logo</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-2" transform="translate(-142.000000, -16.000000)" fill={color}>
                    <path d="M188.75,80.8125 L184.159154,80.8125 L177.0625,66.4838775 L179.438284,61.6875 L188.75,80.8125 Z M166.834633,80.8125 L162.1875,80.8125 L183.301967,38.3125 L204.6875,80.8125 L200.221938,80.8125 L183.527712,47.6361858 L166.834633,80.8125 Z M167.882384,47.2164207 L151.242581,80.8125 L147.3125,80.8125 L168.362422,38.3125 L173.875,49.6357887 L171.492535,54.5068522 L167.882384,47.2164207 Z M181.3125,34.2522736 L175.320132,45.75 L169.625,34.7694666 L175.604933,23.4375 L181.3125,34.2522736 Z M198.257885,84 L210,84 L175.999857,16 L166.805064,34.3899497 L166.574219,34.850781 L142,84 L153.219058,84 L167.897347,54.6438717 L169.711128,58.2717351 L157.009501,84 L168.903027,84 L175.738041,70.3279073 L182.574489,84 L194.357898,84 L181.739146,58.326794 L183.580169,54.6438717 L198.257885,84 Z" id="Fill-20"></path>
                </g>
            </g>
        </svg>
    );
}

export default ArmelcoLogo;
